import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { brandColor, brandColorHover, brandLight } from "../../../constants";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import AboutTreatmentButton from "../productCard/ctaButtons/aboutTreatmentButton";
import OrderNowButton from "../productCard/ctaButtons/orderNowButton";
import AddToOrderButton from "./addToOrderButton";
import hubProductIds from "../../../data/hubProductIds";

const IdsWithoutAboutTreatmentButton = [
  hubProductIds.NESTINGFILE,
  hubProductIds.RUSH,
  hubProductIds.ALLONXPACKAGE,
  hubProductIds.DENTOFACIALANALYSIS,
  hubProductIds.FACIALREFERENCEDIGITALMOUNTINGMODELS
];

const AdditionalProducts = ({ products, selectedTreatment, onAddAdditional, onClickAboutTreatment }) => {
  const [slidesToView, setSlidesToView] = useState(
    window.innerWidth < 768 ? 1 : 3
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesToView(1);
      } else if (window.innerWidth < 1200) {
        setSlidesToView(2);
      } else {
        setSlidesToView(3);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} className="swiper-container">
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true }}
          className="swiper-wrapper"
          slidesPerView={slidesToView}
        >
          {products.map((product, i) => (
            <SwiperSlide key={product.id}>
              <Card sx={{ boxShadow: "none", backgroundColor: "white" }}>
                <CardContent>
                  <Stack
                    justifyContent="space-between"
                    sx={{
                      height: "100%",
                      paddingY: 3,
                      paddingX: 5.25,
                      backgroundColor: brandLight,
                      borderRadius: "24px",
                      boxSizing: "border-box",
                      boxShadow: "0px 13px 30px rgba(138, 138, 138, 0.1)",
                      backdropFilter: "blur(10px)",
                    }}
                  >
                    <Stack
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          maxHeight: "334px",
                          borderRadius: "22px",
                        }}
                      >
                        <Box
                          component="img"
                          src={product.imageLink}
                          sx={{
                            objectFit: "contain",
                            maxWidth: "100%",
                            borderRadius: "22px",
                          }}
                        />
                      </Box>

                      <Stack>
                        <Typography
                          sx={{
                            marginTop: 3,
                            fontSize: "1.125rem",
                            fontWeight: 700,
                            color: "#000",
                          }}
                        >
                          {product.addOnTreatmentId === hubProductIds.NESTINGFILE ? 'Nesting File' : product.treatmentName}
                        </Typography>
                        <Divider
                          sx={{
                            width: "22px",
                            marginY: "12px",
                            border: "1px solid #000",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "0.938rem",
                            fontWeight: 400,
                            minHeight: 100,
                          }}
                        >
                          {product.addOnDescription}
                        </Typography>
                      </Stack>
                    </Stack>
                    <>
                      {(!IdsWithoutAboutTreatmentButton.includes(product.addOnTreatmentId)) 
                      && (
                          <AboutTreatmentButton
                            product={product}
                            onClickAboutTreatment={() => {
                              onClickAboutTreatment(true, product)}
                            }
                          />
                        )}
                        <AddToOrderButton onAdd={() => onAddAdditional(product.addOnTreatmentId)}/>
                    </>
                  </Stack>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Grid>
  );
};

export default AdditionalProducts;
