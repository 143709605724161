import React, { useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Grid,
  DialogActions,
  DialogContent,
  Stack,
  Autocomplete,
  Typography,
  CircularProgress,
} from "@mui/material";
import { countryList } from "./countryList";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import InputText from "../common/inputText";
import { yupResolver } from "@hookform/resolvers/yup";
import { practiceSchema } from "../../types/practiceSchema";
import SelectInput from "../common/selectInput";
import provinces from "./provinces.json";
import AdminService from "../../services/admin.service";
import Header from "../header/header";
import { useLocation } from "react-router";
import AccountService from "../../services/account.service";
import { brandColor, brandLight } from "../../constants";
import { brandColorHover } from "../../constants";
import { brandColorActive } from "../../constants";

const defaultPracticeValues = {
  name: "",
  phone: "",
  specialty: {
    familyDentistry: false,
    pediatricDentistry: false,
    orthodonticsAligners: false,
    implants: false,
    prosthodontics: false,
  },
  address: {
    Line1: "",
    Line2: "",
    City: "",
    State: "",
    Country: "",
    Postal: "",
  },
  equipment: {
    hasIos: false,
    hasCbct: false,
    hasPrinter: false,
    hasChairsideMill: false,
    hasOther: false,
    Ios: "",
    Cbct: "",
    Printer: "",
    ChairsideMill: "",
    Other: "",
  },
};

const iosVendors = [
  "Medit",
  "3Disc",
  "3Shape",
  "iTero",
  "Prime/Omni Scan",
  "Carestream",
  "VeriPulse",
  "Shining 3D",
  "Other",
  "Looking to purchase one",
];

const cbctVendors = [
  "Carestream",
  "Planmeca",
  "Kavo - iCAT",
  "Sirona",
  "Vatech",
  "Acteon",
  "NewTom",
  "Other",
  "Looking to purchase one",
];

const printerVendors = [
  "Formlabs",
  "Asiga",
  "Desktop Health",
  "Ackuretta",
  "Carbon",
  "VeriEko",
  "Phrozen",
  "Uniz",
  "Shining 3D",
  "Looking to purchase one",
];

const chairsideMillVendor = [
  "Cerec",
  "VHF",
  "Amman Girrbach",
  "Planmeca",
  "Other",
  "Looking to purchase one",
];

// const specialtyList = [
//   { label: "Family Dentistry", name: "familyDentistry" },
//   { label: "Pediatric Dentistry", name: "pediatricDentistry" },
//   { label: "Orthdontics Aligners", name: "orthodonticsAligners" },
//   { label: "Implants", name: "implants" },
//   { label: "Prosthodontics", name: "prosthodontics" },
// ];

const equipmentList = [
  { label: "Intra Oral Scanner", name: "hasIos" },
  { label: "CBCT Scanner", name: "hasCbct" },
  { label: "3D Printer", name: "hasPrinter" },
  { label: "Chairside Mill", name: "hasChairsideMill" },
  { label: "Other", name: "hasOther" }
];

const SignUpSSO = ({ practice, open, onClose }) => {
  const location = useLocation();
  const [allPractices, setAllPractices] = useState([]);
  const [isFromSSO, setIsFromSSO] = useState(
    location.state?.userBasicData ? true : false
  );
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultPracticeValues,
    resolver: yupResolver(practiceSchema),
  });
  const [position, setPosition] = React.useState([]);

  const [accountModel, setAccountModel] = useState({
    PersonnelInviteId: "00000000-0000-0000-0000-000000000000",
    learnAboutUs: "Google",
    learnAboutUsOthers: "",
    password: "",
    personnel: {
      Email: location.state.userBasicData.email,
      FirstName: location.state.userBasicData.firstName,
      LastName: location.state.userBasicData.lastName,
      PositionId: "",
      Role: "Dentist",
      UtmSource: "",
      UtmMedium: "",
      UtmCampaign: "",
    },
    practices: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const href = window.location.href.split("/");
  let url = "";
  if (href.length >= 3) {
    for (let i = 0; i < 3; i++) {
      url = url + href[i] + "/";
    }

    // href.forEach((x) => (url = url + x + "/"));
  }
  console.log(url);

  const _loginUrl = process.env.REACT_APP_ACCOUNT_LOGIN + url;
  console.log(_loginUrl);

  useEffect(() => {
    const getAllPractices = async () => {
      const allPracticesResponse = await AdminService.getAllPractices();
      const data = await allPracticesResponse.json();
      console.log(data);
      setAllPractices(data);
    };

    const getAllPositions = async () => {
      await AdminService.getPosition()
        .then((response) => response.json())
        .then((json) => {
          console.log("jsonPosition", json);
          setPosition(json);
          console.log("position", position);
        });
    };

    getAllPositions();
    getAllPractices();
  }, []);

  useEffect(() => {
    if (!open) reset(defaultPracticeValues);
  }, [open, practice, reset]);

  useEffect(() => {
    if (practice) {
      const prac = { ...practice };
      prac.specialty = JSON.parse(prac.specialty);
      prac.equipment = JSON.parse(prac.equipment);
      reset({
        id: prac.id,
        customerRefId: prac.customerRefId,
        name: prac.name,
        phone: prac.phone,
        specialty: {
          familyDentistry: prac.specialty.familyDentistry,
          pediatricDentistry: prac.specialty.pediatricDentistry,
          orthodonticsAligners: prac.specialty.orthodonticsAligners,
          implants: prac.specialty.implants,
          prosthodontics: prac.specialty.prosthodontics,
        },
        address: {
          Line1: prac.address.line1,
          Line2: prac.address.line2,
          City: prac.address.city,
          State: prac.address.state,
          Country: prac.address.country,
          Postal: prac.address.postal,
        },
        equipment: {
          hasIos: prac.equipment.hasIos,
          hasCbct: prac.equipment.hasCbct,
          hasPrinter: prac.equipment.hasPrinter,
          hasChairsideMill: prac.equipment.hasChairsideMill,
          hasOther: prac.equipment.hasOther,
          Ios: prac.equipment.Ios,
          Cbct: prac.equipment.Cbct,
          Printer: prac.equipment.Printer,
          ChairsideMill: prac.equipment.ChairsideMill,
          Other: prac.equipment.Other,
        },
      });
    }
  }, [practice, reset]);

  const equipments = watch("equipment");
  const labName = watch("name");
  const labId = watch("id");
  const country = watch("address.Country");

  const countries = useMemo(
    () => countryList.map((c) => ({ name: c.Name, value: c.Code })),
    []
  );

  // const handleClose = () => {
  //   onClose();
  // };

  const handleAdd = (values) => {
    setIsSubmitting(true);
    values.email = location.state.userBasicData.email;
    values.specialty = JSON.stringify(values.specialty);
    values.equipment = JSON.stringify(values.equipment);

    // setAccountModel((prevState) => ({
    //   ...prevState,
    //   practices: [...prevState.practices, values],
    // }));

    let practices = [];

    practices.push(values);
    accountModel.practices = practices;

    accountModel.personnel.PositionId = position.find(
      (x) => x.description === "Dentist"
    ).id;

    const updateAccount = () => {
      console.log(accountModel, _loginUrl);
      AccountService.ssoUpdate(accountModel, location.state.token)
        .then((response) => response.json())
        .then(async (json) => {
          try {
            await AccountService.logOut();
            window.localStorage.clear();
            window.location.href = _loginUrl;
          } catch (err) {
            //   setErrorMessage("Login failed. Please try again.");
            //   setIsLogin(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          // setIsLogin(false);
          setIsSubmitting(false);
        });
    };

    updateAccount();
  };

  // const handleClick = (p) => {
  //   if (!p) return;
  //   p.specialty =
  //     typeof p.specialty === "string" ? JSON.parse(p.specialty) : p.specialty;
  //   p.equipment =
  //     typeof p.equipment === "string" ? JSON.parse(p.equipment) : p.equipment;

  //   reset({
  //     id: p.id,
  //     customerRefId: p.customerRefId,
  //     name: p.name,
  //     phone: p.phone,
  //     specialty: {
  //       familyDentistry: p.specialty.familyDentistry,
  //       pediatricDentistry: p.specialty.pediatricDentistry,
  //       orthodonticsAligners: p.specialty.orthodonticsAligners,
  //       implants: p.specialty.implants,
  //       prosthodontics: p.specialty.prosthodontics,
  //     },
  //     address: {
  //       Line1: p.address.line1,
  //       Line2: p.address.line2,
  //       City: p.address.city,
  //       State: p.address.state,
  //       Country: p.address.country,
  //       Postal: p.address.postal,
  //     },
  //     equipment: {
  //       hasIos: p.equipment.hasIos,
  //       hasCbct: p.equipment.hasCbct,
  //       hasPrinter: p.equipment.hasPrinter,
  //       hasChairsideMill: p.equipment.hasChairsideMill,
  //       Ios: p.equipment.Ios,
  //       Cbct: p.equipment.Cbct,
  //       Printer: p.equipment.Printer,
  //       ChairsideMill: p.equipment.ChairsideMill,
  //     },
  //   });
  //   setValue("address.Country", p.address.country, { shouldValidate: true });

  //   // specialty
  //   setValue("specialty.familyDentistry", p.specialty.familyDentistry, {
  //     shouldValidate: true,
  //   });
  //   setValue("specialty.pediatricDentistry", p.specialty.pediatricDentistry, {
  //     shouldValidate: true,
  //   });
  //   setValue(
  //     "specialty.orthodonticsAligners",
  //     p.specialty.orthodonticsAligners,
  //     {
  //       shouldValidate: true,
  //     }
  //   );
  //   setValue("specialty.implants", p.specialty.implants, {
  //     shouldValidate: true,
  //   });
  //   setValue("specialty.prosthodontics", p.specialty.prosthodontics, {
  //     shouldValidate: true,
  //   });

  //   // equipment
  //   setValue("equipment.hasIos", p.equipment.hasIos, {
  //     shouldValidate: true,
  //   });
  //   setValue("equipment.hasCbct", p.equipment.hasCbct, {
  //     shouldValidate: true,
  //   });
  //   setValue("equipment.hasPrinter", p.equipment.hasPrinter, {
  //     shouldValidate: true,
  //   });
  //   setValue("equipment.hasChairsideMill", p.equipment.hasChairsideMill, {
  //     shouldValidate: true,
  //   });
  // };

  const practicesToShow = () => {
    return labName?.length > 2 ? allPractices : [];
  };

  return (
    <>
      {/* {allPractices.length === 0 && <LoadingModal open message="" />} */}
      {/* {allPractices.length > 0 && ( */}
      <>
        <Header
          doctorName={
            isFromSSO
              ? `${location.state.userBasicData.firstName} ${location.state.userBasicData.lastName}`
              : null
          }
        />

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={4}>
            <Box
              component="form"
              onSubmit={handleSubmit(handleAdd)}
              sx={{
                width: "100%",
                background: brandLight,
                borderRadius: "10px",
                marginTop: "20px",
              }}
            >
              <DialogContent>
                <Box sx={{ textAlign: "center", padding: 2 }}>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: "25px", fontWeight: "700" }}
                  >
                    Please add your practice information to complete your
                    profile
                  </Typography>
                </Box>
                <Box sx={{ padding: 2 }}>
                  <Stack spacing={1}>
                    
                  <Box backgroundColor="white">
                      <Controller
                        control={control}
                        name="name"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            disablePortal
                            options={practicesToShow()}
                            freeSolo
                            getOptionLabel={(option) => option.name || option}
                            value={value}
                            onChange={(_, val) => {
                              console.log("hi");
                              onChange(val?.name || "");
                             // handleClick(val);
                            }}
                            onInputChange={(_, val) => {
                              if (labId) {
                                reset(defaultPracticeValues);
                              }
                              onChange(val);
                            }}
                            renderInput={(params) => (
                              <TextField
                                sx={{ fontSize: "20px" }}
                                placeholder="Practice Name"
                                {...params}
                                size="small"
                                error={Boolean(error)}
                                FormHelperTextProps={{
                                  variant: "standard",
                                }}
                                helperText={error && error.message}
                              />
                            )}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        )}
                      />
                    </Box>


                    <InputText
                      sx={{
                        fontSize: "15px",
                        height: "45px",
                        backgroundColor: "#ffffff",
                      }}
                      placeholder="Address Line 1"
                      register={register("address.Line1")}
                      errors={errors.address?.Line1}
                      disabled={Boolean(labId) || isSubmitting}
                    />

                    <InputText
                      sx={{
                        fontSize: "15px",
                        height: "45px",
                        backgroundColor: "#ffffff",
                      }}
                      placeholder="Address Line 2"
                      register={register("address.Line2")}
                      errors={errors.address?.Line2}
                      disabled={Boolean(labId) || isSubmitting}
                    />

                    <InputText
                      sx={{
                        fontSize: "15px",
                        height: "45px",
                        backgroundColor: "#ffffff",
                      }}
                      placeholder="City"
                      register={register("address.City")}
                      errors={errors.address?.City}
                      disabled={Boolean(labId) || isSubmitting}
                    />

                    <SelectInput
                      label="Select Country"
                      name="address.Country"
                      control={control}
                      items={countries}
                      disabled={Boolean(labId) || isSubmitting}
                    />

                    {country === "CA" && (
                      <SelectInput
                        label="State / Province"
                        name="address.State"
                        control={control}
                        items={provinces.canada.map((p) => ({
                          name: p.name,
                          value: p.name,
                        }))}
                        disabled={Boolean(labId) || isSubmitting}
                      />
                    )}
                    {country === "US" && (
                      <SelectInput
                        label="State / Province"
                        name="address.State"
                        control={control}
                        items={provinces.usa.map((p) => ({
                          name: p.name,
                          value: p.name,
                        }))}
                        disabled={Boolean(labId) || isSubmitting}
                      />
                    )}
                    {country !== "CA" && country !== "US" && (
                      <InputText
                        sx={{
                          fontSize: "15px",
                          height: "45px",
                          backgroundColor: "#ffffff",
                        }}
                        placeholder="State / Province"
                        register={register("address.State")}
                        errors={errors.address?.State}
                        disabled={Boolean(labId) || isSubmitting}
                      />
                    )}

                    <InputText
                      sx={{
                        fontSize: "15px",
                        height: "45px",
                        backgroundColor: "#ffffff",
                      }}
                      placeholder="Postal"
                      register={register("address.Postal")}
                      errors={errors.address?.Postal}
                      disabled={Boolean(labId) || isSubmitting}
                    />

                    <InputText
                      sx={{
                        fontSize: "15px",
                        height: "45px",
                        backgroundColor: "#ffffff",
                      }}
                      placeholder="Phone Number"
                      register={register("phone")}
                      errors={errors.phone}
                      disabled={Boolean(labId) || isSubmitting}
                    />
                  </Stack>

                  <Grid container spacing={1} sx={{ marginTop: 1 }}>
                    {/* <Grid item xs={12}>
                    <Box sx={{ marginBottom: "15px" }}>
                      <Box
                        sx={{
                          marginBottom: 1,
                          fontWeight: "300",
                          fontSize: "18px",
                          lineHeight: "26px",
                          color: "#585656",
                        }}
                      >
                        Select all fields that apply to your practice.
                      </Box>
                      <Stack>
                        {specialtyList.map((specialty) => (
                          <Controller
                            key={JSON.stringify(specialty)}
                            name={`specialty.${specialty.name}`}
                            control={control}
                            render={({ field }) => (
                              <FormControlLabel
                                label={specialty.label}
                                sx={{
                                  fontWeight: "300",
                                  fontSize: "18px",
                                  lineHeight: "26px",
                                  color: "#585656",
                                  textAlign: "center",
                                }}
                                disabled={Boolean(labId)}
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={field[`value`] ?? false}
                                  />
                                }
                              />
                            )}
                          />
                        ))}
                      </Stack>
                    </Box>
                  </Grid> */}
                    <Grid item xs={12}>
                      <Box>
                        <Typography
                          component="h3"
                          variant="h3"
                          sx={{ fontSize: "20px", fontWeight: "600" }}
                        >
                          Select primary equipment that your practice owns.
                        </Typography>
                        {errors.equipment?.message && (
                          <Typography color="error" variant="body2">
                            {errors.equipment.message}
                          </Typography>
                        )}
                      </Box>

                      {equipmentList.map((equipment) => (
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name={`equipment.${equipment.name}`}
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  label={equipment.label}
                                  sx={{
                                    fontWeight: "400",
                                    color: "#585656",

                                    borderColor: "transparent",
                                    "& .MuiFormControlLabel-label": {
                                      fontSize: "15px",
                                    },
                                  }}
                                  disabled={Boolean(labId) || isSubmitting}
                                  defaultChecked={field["value"] === equipment}
                                  control={
                                    <Checkbox
                                      {...field}
                                      checked={field["value"] ?? false}
                                    />
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {equipments.hasIos &&
                              equipment.label === "Intra Oral Scanner" && (
                                <SelectInput
                                  control={control}
                                  name="equipment.Ios"
                                  items={iosVendors.map((i) => ({
                                    name: i,
                                    value: i,
                                  }))}
                                  disabled={Boolean(labId) || isSubmitting}
                                  fullWidth
                                />
                              )}
                            {equipments.hasCbct &&
                              equipment.label === "CBCT Scanner" && (
                                <SelectInput
                                  control={control}
                                  name="equipment.Cbct"
                                  register={register("equipment.Cbct")}
                                  items={cbctVendors.map((i) => ({
                                    name: i,
                                    value: i,
                                  }))}
                                  disabled={Boolean(labId) || isSubmitting}
                                  fullWidth
                                />
                              )}
                            {equipments.hasPrinter &&
                              equipment.label === "3D Printer" && (
                                <SelectInput
                                  control={control}
                                  name="equipment.Printer"
                                  items={printerVendors.map((i) => ({
                                    name: i,
                                    value: i,
                                  }))}
                                  disabled={Boolean(labId) || isSubmitting}
                                  fullWidth
                                />
                              )}
                            {equipments.hasChairsideMill &&
                              equipment.label === "Chairside Mill" && (
                                <SelectInput
                                  control={control}
                                  name="equipment.ChairsideMill"
                                  items={chairsideMillVendor.map((i) => ({
                                    name: i,
                                    value: i,
                                  }))}
                                  errors={
                                    errors.equipment &&
                                    errors.equipment.ChairsideMill
                                  }
                                  disabled={Boolean(labId) || isSubmitting}
                                  fullWidth
                                />
                              )}
                            {equipments.hasOther &&
                              equipment.name === "hasOther" && (
                                <InputText
                                  sx={{
                                    fontSize: "15px",
                                    height: "45px",
                                    backgroundColor: "#ffffff",
                                  }}
                                  placeholder="Please specify"
                                  register={register("equipment.Other")}
                                  errors={errors.equipment?.Other}
                                  disabled={Boolean(labId) || isSubmitting}
                                  fullWidth
                                />
                              )}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions sx={{ paddingBottom: 4, paddingX: 4 }}>
                {isSubmitting ? (
                  <CircularProgress color="success" size="1.5rem" />
                ) : (
                  <Button
                    sx={{
                      backgroundColor: brandColor,
                      color: "white",
                      fontSize: "15px",
                      lineHeight: "1.2em",
                      fontWeight: "400",
                      paddingX: 2.5,
                      paddingY: 1,
                      borderRadius: "30px",
                      ":hover": {
                        backgroundColor: brandColorHover,
                      },
                      ":active": {
                        backgroundColor: brandColorActive,
                      },
                    }}
                    variant="contained"
                    type="submit"
                    color="brand"
                  >
                    Save
                  </Button>
                )}
              </DialogActions>
            </Box>
          </Grid>
        </Grid>
      </>
      {/* )} */}
    </>
  );
};

export default SignUpSSO;
