export default { 
    CrownAndBridge: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'FD51F965-142A-43EA-BD6C-C6C6A7B7922E' : 'fd51f965-142a-43ea-bd6c-c6c6a7b7922e',
    FCZ: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'AE1A0430-94B0-4A40-BB9C-A380A82A183E' : 'ae1a0430-94b0-4a40-bb9c-a380a82a183e',
    PFZ: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '54D064AD-C9E0-4A08-BCD1-E11847B6927E' : '54d064ad-c9e0-4a08-bcd1-e11847b6927e',
    CASTS: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'BDBF7F51-3BE7-4F48-A629-B58FC7948907' : '8b0a8e05-e6e3-4120-a371-38741051313f',
    FACIALREFERENCEDIGITALMOUNTINGMODELS: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '2B5FFBF8-555C-4A3D-BD61-7A836E37FCB6' : '2b5ffbf8-555c-4a3d-bd61-7a836e37fcb6',
    NESTINGFILE: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'F5B1E6AD-52C0-444B-9EA5-8228D778C8EB' : 'f5b1e6ad-52c0-444b-9ea5-8228d778c8eb',
    IMPLANTCROWN: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '5F9548AC-D468-4346-91FE-4BE6579488BE' : '7fd1e094-1e65-4951-9aff-4d0c5075be3f',
    ALLONX: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '22F99FDA-9993-4954-BEA9-F04AB423E78F' : '88a1ade1-016a-47a4-890e-a2bec7743165',
    ALLONXPACKAGE: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '0F6E91BF-171E-4A14-8E30-B6181F8735E1' : '0f6e91bf-171e-4a14-8e30-b6181f8735e1',
    RUSH: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'a23f49df-4156-4311-b363-63e6bd37dafd' : '55d25cb2-4166-40ec-b91f-8e9c12973ea4',
    PHOTOCASEPRESENTATION: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'F7569666-8956-4DEE-BC20-596C748DBA11' : 'c350f394-54bd-42f3-8d78-41d7f8b99604',
    DENTOFACIALANALYSIS: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '3951CFBE-81E4-4E78-9870-B27B2A683D52' : '4828c26c-eb01-4ccc-a808-d8aed580ae33',
    DEVICES: process.env.REACT_APP_ENVIRONMENT === 'prod' ? '7B825182-E2D7-4EEB-8162-9891C0A75A05' : '802f21bd-2cc6-4788-90b7-df3efcb489de'
}