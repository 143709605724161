import { Box } from "@mui/system";
import React from "react";
import { Chip, Input, List, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  brandColor,
  brandGray,
  brandLight,
  brandLightGreen,
} from "../../constants";

const SearchFilter = ({ filterList, searchFilter, onSelect, practices }) => {
  const handleSelect = (val) => {
    onSelect(val);
  };
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ margin: "10px" }}
    >
      <List component={Stack} direction="row" sx={{ width: "50%" }}>
        {filterList.map((filter, index) => (
          <Chip
            disabled={!practices.length}
            key={filter.name}
            sx={{
              margin: "10px",
              background:
                filter.name === searchFilter ? brandLightGreen : brandLight,

              "&:hover": {
                background:
                  filter.name === searchFilter ? brandLightGreen : brandLight,
              },

              color: filter.name === searchFilter ? "#fff" : "#000",
            }}
            label={
              <React.Fragment>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ margin: "10px" }}
                >
                  <Box
                    onSelect={() => console.log("aa")}
                    as="span"
                    sx={{ fontWeight: "bold" }}
                  >
                    {filter.name}
                  </Box>

                  {filter.count > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        backgroundColor: "#5B5B5B",
                        color: "#fff",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "15px",
                      }}
                    >
                      {filter.count}
                    </Box>
                  )}
                </Stack>
                {/* needs to be zipped. */}
              </React.Fragment>
            }
            onClick={() => handleSelect(filter.name)}
          />
        ))}
      </List>
    </Stack>
  );
};

export default SearchFilter;
