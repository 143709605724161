import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";
import Header from "../../components/header/header";
import Table from "../../components/common/table/table";
import Searchbar from "../../components/patients/searchBar";
import PracticeTabs from "../../components/patients/practiceTabs";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminService from "../../services/admin.service";
import moment from "moment";
import PatientMenu from "../../components/patients/patientMenu";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import PatientService from "../../services/patient.service";
import DataTable from "../../components/common/table/dataTable";
import TableRow from "../../components/common/table/tableRow";
import SearchFilter from "../../components/caseTracker/searchFilter";
import ExpandingTableRow from "../../components/common/table/expandingTableRow";

import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import diagnosticWaxUpsBanner from "./../../images/banners/DX Banner.png";
import PageLayout from "../../components/common/pageLayout";
import { grey } from "@mui/material/colors";
import TableSkeleton from "../../components/common/skeleton/tableSkeleton";
import { getRandomInt } from "../../helpers/getRandomInt";
import EmptyAlert from "../../components/patient/emptyAlert";
import BannerImage from "../../images/banners/dx_steps.png";
import { updatePatientId } from "../../redux/actions/index";

import {
  brandColor,
  brandBlue,
  brandRed,
  brandOrange,
  brandGray,
  brandLight,
  brandBlack
} from "../../constants";
import ResubmitDialog from "../../components/caseTracker/resubmitDialog";
import ProceedToFinalDialog from "../../components/caseTracker/proceedToFinalDialog";
import ProceedToFinalButton from "../../components/caseTracker/proceedToFinalButton";
import { Mail, MarkUnreadChatAlt } from "@mui/icons-material";
import ActionButton from "../../components/common/button/actionButton";
import LoadingModal from "../../components/common/modal/loadingModal";
import AlignerFabModal from "../../components/common/modal/alignerFabricationModal";
import CaseService from "../../services/case.service";
import { openSnackbar } from "../../redux/actions";

const Cases = () => {
  const [selectedPractice, setSelectedPractice] = useState();
  const [practices, setPractices] = useState([]);
  const [cases, setCases] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusFilter, setStatusFilter] = useState("All Orders");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterList, setFilterList] = useState([
    { name: "All Orders", count: 0 },
    { name: "Case In Progress", count: 0 },
    { name: "Case For Dentist Approval", count: 0 },
    { name: "Finalizing Design", count: 0 },
    { name: "Completed", count: 0 },
    { name: "Pre-booked Cases", count: 0 },
  ]);
  const [prebooks, setPrebooks] = useState([]);
  const [totalPrebooks, setTotalPrebooks] = useState([]);
  const [totalCasesCount, setTotalCasesCount] = useState(0);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.userInfo);
  const isDentist = userInfo.Roles.includes("Dentist");

  const search = searchParams.get("search");
  const type = searchParams.get("type");

  const [isFetchingPatient, fetchingPatient] = useState(false);
  const [isALFabModalOpen, alfabModalOpen] = useState(false);
  const [alFabModalCaseData, setalFabModalCaseData] = useState('');
  const [alFabModalFilesData, setalFabModalFilesData] = useState('');
  const handleRowClicks = cases.map((row) => {
    return function () {
      navigate("/cases/" + row.id);
    };
  });

  const getHubStatus = (status) => {
    switch (status) {
      case "Sent":
        return "Order Received";
      case "In Progress":
        return "Case In Progress";
      case "For Approval":
        return "Case For Dentist Approval";
      case "For Completion":
        return "Finalizing Design";
      case "Received":
        return "Completed";
      case "Completed":
        return "Completed";
      case "Cancelled":
        return "Cancelled";
      default:
        return status;
    }
  };

  const getPackageLabels = (productId, name) => {
    if (productId === 1002 || productId === 1249) {
      return "Diagnostic Wax Up";
    } else if (productId === 1054) {
      return "Trial Smile (One-piece veneer style to try in over the patient's existing teeth)";
    } else if (productId === 1234) {
      return "Virtually prepped shell temps (with a 0.6 mm virtual prep)";
    } else if (productId === 1235) {
      return "Prepped shell temps (chairside prep and IOS scan)";
    } else if (productId === 1236) {
      return "PDF Presentation";
    } else if (productId === 1243) {
      return "Appended Model on DX Design";
    } else if (productId === 1244) {
      return "Appended Model with Alternating Tooth";
    } else if (productId === 1016) {
      return "Gingival Reduction Guide";
    } else {
      return name;
    }
  };

  const statusIcon = (status, row) => {
    let statuses = [];
    if (status === "Case For Dentist Approval") {
      statuses.push(
        <NotificationsActiveRoundedIcon style={{ color: "#f53434" }} />
      );
    }
    if (
      status === "Case In Progress" &&
      row.flags.rescan &&
      row.latestTask.serviceProviderId ===
        "00000000-0000-0000-0000-000000000000" &&
      (row.flags.rescanUploaded === false ||
        row.flags.rescanUploaded === null) &&
      !row.isUploadingRescan
    ) {
      statuses.push(<AddBoxRoundedIcon style={{ color: "#bbbb09" }} />);
    }
    if (status === "Completed") {
      statuses.push(<CheckCircleRoundedIcon style={{ color: "#32be60" }} />);
    }
    if (row.unreadComments > 0) {
      statuses.push(<MarkUnreadChatAlt style={{ color: "orange" }} />);
    }

    return (
      <Stack spacing={1} sx={{ fontSize: "1.3em" }}>
        {statuses}
      </Stack>
    );
  };

  const showAllOnXProceedToFinal = (caseDetails, status) => {
    // Is all on x implants or all on x package
    // all on x implant = preprod
    // al on x package = prod
    const isAllOnX = ["all on x implants", "all on x package"].some((con) =>
      caseDetails.treatment.map((c) => c.toLowerCase()).includes(con)
    );
    if (!isAllOnX) return false;

    const caseTreatments = caseDetails.caseTreatments[0];
    const standardPackage = caseTreatments.casePackages.find(
      (p) => p.productId === 1044
    );
    if (!standardPackage) return false;

    const ziconiaPackage = caseTreatments.casePackages.find(
      (p) => p.productId === 1219
    );
    if (!ziconiaPackage) return false;

    if (standardPackage.status === "For Completion") {
      return true;
    }
  };

  const handleContinueToWaxUpClick = async (id) => {
    const name = "diagnostic wax-up";
    fetchingPatient(true);
    try {
      const [caseResponse, filesResponse] = await Promise.all([
        CaseService.getCaseById(id),
        CaseService.getallcasefiledownloads(id),
      ]);

      const [caseData, filesData] = await Promise.all([
        caseResponse.json(),
        filesResponse.json(),
      ]);
      const { patientId, caseRefNo } = caseData;
      const { files } = filesData;

      navigate(
        `/?treatment=${encodeURIComponent(name.trim())}&patientId=${patientId}`,
        {
          state: {
            additionalOrderDetails: {
              notes: `Continued from case number: ${caseRefNo}`,
              filePathsToInclude: files.map((f) => f.filePath),
              updatedProductId: 1249,
            },
          },
        }
      );
    } catch (err) {
      dispatch(
        openSnackbar({
          title: "Something went wrong",
          body: "There was a problem redirecting you to the diagnostic wax up ordering page.",
          type: "error",
        })
      );
      fetchingPatient(false);
    }
  };

  const handleContinueToFinalRestoration = async (id) => {
    const name = "all on x implants";
    fetchingPatient(true);
    try {
      const [caseResponse, filesResponse] = await Promise.all([
        CaseService.getCaseById(id),
        CaseService.getallcasefiledownloads(id),
      ]);

      const [caseData, filesData] = await Promise.all([
        caseResponse.json(),
        filesResponse.json(),
      ]);
      const { patientId, caseRefNo } = caseData;
      const { files } = filesData;

      navigate(
        `/?treatment=${encodeURIComponent(name.trim())}&patientId=${patientId}`,
        {
          state: {
            additionalOrderDetails: {
              notes: `Continued from case number: ${caseRefNo}`,
              filePathsToInclude: files.map((f) => f.filePath),
              updatedProductId: 1219,
            },
          },
        }
      );
    } catch (err) {
      dispatch(
        openSnackbar({
          title: "Something went wrong",
          body: "There was a problem redirecting you to the all on x implants ordering page.",
          type: "error",
        })
      );
      fetchingPatient(false);
    }
  };

  const handleContinueToFabrication = async (id) => {
    const name = "aligner fabrication only";
    

    try {
      const [caseResponse, filesResponse] = await Promise.all([
        CaseService.getCaseById(id),
        CaseService.getallcasefiledownloads(id),
      ]);

      const [caseData, filesData] = await Promise.all([
        caseResponse.json(),
        filesResponse.json(),
      ]);
      const { patientId, caseRefNo } = caseData;
      const { files } = filesData;
      const practiceData  = practices;

      //load case modal
      setalFabModalCaseData(caseData);
      setalFabModalFilesData(filesData);
      alfabModalOpen(true);
      
    } catch (err) {
      dispatch(
        openSnackbar({
          title: "Something went wrong",
          body: "There was a problem redirecting you to the aligner fabrication ordering page.",
          type: "error",
        })
      );
      fetchingPatient(false);
      alfabModalOpen(false);
    }
  };
  
  const addTreatmentClickHandler = (patientdId) => {
    dispatch(updatePatientId(patientdId));
    navigate('/');
  }

  const buttonAction = (status, row, p) => {
    const buttons = [];
    
    const getMainButton = () => {
      if (status === "Case For Dentist Approval") {
        return (
          <ActionButton
            color="brandRed"
            onClick={() => {
              navigate(`/cases/${row.id}/case-result`);
            }}
          >
            Approve Design
          </ActionButton>
        );
      } else if (
        status === "Case In Progress" &&
        row.flags.rescan &&
        row.latestTask.serviceProviderId ===
          "00000000-0000-0000-0000-000000000000" &&
        (row.flags.rescanUploaded === false ||
          row.flags.rescanUploaded === null) &&
        !row.isUploadingRescan
      ) {
        return (
          <ActionButton
            color="brandOrange"
            onClick={() => {
              navigate(`/cases/${row.id}/case-result`);
            }}
          >
            Add More Files
          </ActionButton>
        );
      } else if (status === "Completed") {
        return (
          <Box display="flex" flexDirection="column" >
            <Stack marginY="10px">
              <ActionButton
                color="brand"
                onClick={() => {
                  navigate(`/cases/${row.id}/case-files`);
                }}
              >
                Download Design
              </ActionButton>
            </Stack>
            <Stack>
              {!p && <Button
                disableElevation
                sx={{
                  margin: "10px 0",
                  minWidth: "140px",
                  height: "38px",
                  paddingY: 0.5,
                  paddingX: 2,
                  borderRadius: "30px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  lineHeight: "14px",
                  backgroundColor: "#000",
                  color: "#FFF",
                  '&:hover': {
                    backgroundColor: '#333',
                  },
                }}
                onClick={()=>addTreatmentClickHandler(row.patientId)}
                >
                Continue with Case
              </Button>}
            </Stack>
          </Box>
          
        );
      } else if (row.unreadComments > 0) {
        return (
          <ActionButton
            color="brandBlue"
            onClick={() => {
              navigate(`/cases/${row.id}/case-chat`);
            }}
          >
            Read Message
          </ActionButton>
        );
      } else if (
        (status === "Finalizing Design" || status === "Case In Progress") &&
        p?.productId !== 1219 &&
        showAllOnXProceedToFinal(row, status)
      ) {
        return <ProceedToFinalButton caseDetails={row} />;
      } else {        
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
              <Box
                sx={{margin: "10px 0"}}
              >
                <ActionButton
                  color="brandBlue"
                  onClick={() => {
                    navigate(`/cases/${row.id}/case-result`);
                  }}
                >
                  Track Progress
                </ActionButton>
              </Box>

              {!p && <Button
                disableElevation
                sx={{
                  margin: "10px 0",
                  minWidth: "140px",
                  height: "38px",
                  paddingY: 0.5,
                  paddingX: 2,
                  borderRadius: "30px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  lineHeight: "14px",
                  backgroundColor: "#000",
                  color: "#FFF",
                  '&:hover': {
                    backgroundColor: '#333',
                  },
                }}
                onClick={()=>addTreatmentClickHandler(row.patientId)}
                >
                Add Treatment
              </Button>}
          </Box>
          
        );
      }
    };

    buttons.push(getMainButton());

    // if dfa and completed
    if (
      row.treatment
        .map((t) => t.trim().toLowerCase())
        .some((treatment) =>
          ["dentofacial display analysis", "virtual smile mock up-2d"].includes(
            treatment
          )
        ) &&
      row.latestTask.status === "Completed"
    ) {
      buttons.push(
        <ActionButton
          color="brandBlue"
          onClick={() => handleContinueToWaxUpClick(row.id)}
        >
          Continue With <br /> Wax Up
        </ActionButton>
      );
    }

    // if all on x and completed
    if (
      row.treatment
        .map((t) => t.trim().toLowerCase())
        .some((treatment) => ["all on x standard"].includes(treatment)) &&
      row.latestTask.status === "Completed"
    ) {
      buttons.push(
        <ActionButton
          color="brandBlue"
          onClick={() => handleContinueToFinalRestoration(row.id)}
        >
          Continue to <br /> Final Restoration
        </ActionButton>
      );
    }
    //if aligner planning only and completed
    if (
      row.treatment
        .map((t) => t.trim().toLowerCase())
        .some((treatment) => ["aligner planning only"].includes(treatment)) &&
      row.latestTask.status === "Completed"
    ) {
      buttons.push(
        <ActionButton
          color="brandBlue"
          onClick={() => handleContinueToFabrication(row.id)}
        >
          Continue with <br /> Fabrication
        </ActionButton>
      );
    }

    return (
      <Stack
        width="100%"
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        {buttons.map((buttonElement) => buttonElement)}
      </Stack>
    );
  };

  useEffect(() => {
    const rowData = cases.map((c, i) => {
      let packages = c.caseTreatments[0].casePackages;

      let isPackageForApproval = false;

      if (packages.length > 0) {
        packages.map((p, idx) => {
          if (p.status === "For Approval") {
            isPackageForApproval = true;
          }
        });
      }
      var title = [];
      c?.treatment?.forEach((tt, ttidx) => {
        let treatmentTitle = tt;

        if (
          tt ===
            "Trial Smile (One-piece veneer style to try in over the patients existing teeth - consultative purposes)" ||
          tt === "Standard temporaries (prepped teeth)"
        ) {
          treatmentTitle = "Crown & Bridge";
        }

        title.push(<p style={{ margin: "0px" }}>{treatmentTitle}</p>);
      });

      console.log(title, c?.treatment);

      return [
        i + 1,
        <Stack spacing={0}>{title}</Stack>,

        c.caseRefNo,
        c?.latestTask?.status === "For Completion" &&
        c.treatment[0] === "Aligners" ? (
          "For Fabrication"
        ) : (
          <Stack justifyContent="space-between" direction="row">
            {getHubStatus(c?.latestTask?.status)}
          </Stack>
        ),

        `${c.patientLastName}, ${c.patientFirstName}`,

        !userInfo.Roles.find((x) => x === "Dentist") && c.doctorName,
        c.updatedAt ? moment(c.updatedAt).format("ll") : "N/A",
        <IconButton
          color="brandBlue"
          sx={{ margin: "0 auto" }}
          onClick={() => {
            navigate(`/cases/${c.id}/case-chat`);
          }}
        >
          <Mail />
        </IconButton>,
        isPackageForApproval
          ? buttonAction(getHubStatus("For Approval"), c)
          : buttonAction(getHubStatus(c?.latestTask?.status), c),
        isPackageForApproval
          ? statusIcon(getHubStatus("For Approval"), c)
          : statusIcon(getHubStatus(c?.latestTask?.status), c),
      ];
    });

    setRows(rowData);
  }, [cases]);

  const expandContents = cases.map((cl, i) => {
    let packages = cl.caseTreatments[0].casePackages;

    const dataExpand = {
      headers: [
        "#",
        "Treatment Name",
        "Case Number",
        "Status",
        "Patient Name",
        "Last Updated",
        "",
        "",
      ],
    };
    if (cl.caseRefNo === "831294343") {
      console.log("Hey", cl, packages);
    }

    dataExpand.rows = packages.map((p, idx) => {
      return [
        idx + 1,
        getPackageLabels(p.productId, p.name),
        cl.caseRefNo,
        <Stack justifyContent="space-between" direction="row">
          {getHubStatus(p.status)}
        </Stack>,

        `${cl.patientLastName}, ${cl.patientFirstName}`,
        cl.updatedAt ? moment(cl.updatedAt).format("ll") : "N/A",

        buttonAction(getHubStatus(p.status), cl, p),
        statusIcon(getHubStatus(p.status), cl),
      ];
    });

    return (
      dataExpand.rows.length > 0 && (
        <DataTable headers={dataExpand.headers} sx={{ paddingX: 2 }}>
          {dataExpand.rows.map((row, i) => (
            <TableRow key={i} row={row} shouldUseDivider />
          ))}
        </DataTable>
      )
    );
  });

  useEffect(() => {
    if (statusFilter === "Pre-booked Cases") {
      return;
    }
    GetCaseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, statusFilter]);

  useEffect(() => {
    setStatusFilter("All Orders");
    if (prebooks.length > 0) {
      setPrebooks([]);
    }
    GetCaseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPractice]);

  useEffect(() => {
    const getPractices = async () => {
      const practicesResponse = await AdminService.getPractices(userInfo.Id);
      const { practices } = await practicesResponse.json();

      const formattedPractices = practices.map(({ id, name }) => ({
        id,
        name,
      }));
      setPractices(formattedPractices);

      if (userInfo.Roles.includes("Dentist")) {
        let defaultPractice = practices.find((x) => x.isPrimary === true);
        console.log(defaultPractice);
        if (
          defaultPractice !== null &&
          defaultPractice !== undefined &&
          defaultPractice !== "undefined"
        ) {
          setSelectedPractice(defaultPractice.id);
        } else {
          setSelectedPractice(formattedPractices[0].id);
        }
      } else {
        setSelectedPractice(formattedPractices[0].id);
      }
     // localStorage.setItem('locSelectedPractice', selectedPractice);
    };

    getPractices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetCaseList = (skip = 0) => {
    if (!selectedPractice) return;

    let currentCases = cases;
    // if (cases || cases.length > 0) setCases([]);
    if (skip === 0) {
      currentCases = [];
      setCases([]);
    } else {
      currentCases = cases;
    }

    const getCases = async () => {
      setLoading(true);
      var pId = userInfo.PracticeWithPersonnel.filter(
        (p) => p.practiceId === selectedPractice
      ).map((x) => x.personnelId);
 
      localStorage.setItem('locSelectedPractice', selectedPractice);

      const casesResponse = await PatientService.getCasesByPersonnel({
        personnelId: pId,
        practiceId: selectedPractice,
        search: search,
        statusFilter: statusFilter,
        skip: skip,
      });

      const preorderCountResponse = await CaseService.getPrebookCount({
        personnelIds: pId,
        practiceId: selectedPractice,
      });
      const preorderCountJson = await preorderCountResponse.json();
      const preorderCount = preorderCountJson.count;

      await casesResponse.json().then((response) => {
        console.log("PSPP", response);
        let newArr = filterList.map((item, i) => {
          if (item.name === "All Orders") {
            return {
              ...item,
              count: response.allOrders,
            };
          } else if (item.name === "Case In Progress") {
            return {
              ...item,
              count: response.ordersBeingDone,
            };
          } else if (item.name === "Case For Dentist Approval") {
            return {
              ...item,
              count: response.ordersNeededAction,
            };
          } else if (item.name === "Completed") {
            return {
              ...item,
              count: response.completedOrders,
            };
          } else if (item.name === "Finalizing Design") {
            return {
              ...item,
              count: response.finalizingDesign,
            };
          } else if (item.name === "Pre-booked Cases") {
            return {
              ...item,
              count: preorderCount,
            };
          } else {
            return item;
          }
        });
        setFilterList(newArr);

        response?.caseList.forEach((cl) => {
          let task = [];
          cl.caseTreatments.forEach((ct) => {
            ct?.serviceProviders.forEach((sp) => {
              if (sp.lastModified) {
                task.push(sp);
              }
            });
          });

          task.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.lastModified) - new Date(a.lastModified);
          });

          if (task.length < 1) {
            var tempLatestTask =
              cl.caseTreatments && cl.caseTreatments.length > 0
                ? cl.caseTreatments[0].serviceProviders[0]
                : null;

            if (tempLatestTask) {
              tempLatestTask.status = "Sent";
              cl.latestTask = tempLatestTask;
            }
          } else {
            cl.latestTask = task[0];
          }
        });

        currentCases = currentCases.concat(response.caseList);
        setCases(currentCases);
        setTotalCasesCount(response.caseCountWithFilter);
        setLoading(false);
      });
    };

    getCases();
  };

  const handleSearch = (value) => {
    navigate("?search=" + value);
  };

  const handleStatusFilter = async (value) => {
    if (value === "Pre-booked Cases") {
      setLoading(true);
      const response = await CaseService.getPrebooks({
        personnelIds: userInfo.PracticeWithPersonnel.filter(
          (p) => p.practiceId === selectedPractice
        ).map((x) => x.personnelId),
        practiceId: selectedPractice,
        skip: 0,
        take: 20,
      });
      const data = await response.json();
      setPrebooks(data.schedules);
      setTotalPrebooks(data.count);
      setLoading(false);
    } else {
      if (type === "pre-book") {
        setSearchParams({});
      }
      setPrebooks([]);
    }

    setStatusFilter(value);
  };

  const handlePracticeChange = (id) => {
    setSelectedPractice(id);    
    localStorage.setItem('locSelectedPractice', id);
  };

  const skeletonCount = useMemo(() => getRandomInt(2, 20), [loading]);

  const headers = [
    "#",
    "Treatment Name",
    "Case Number",
    "Status",
    "Patient Name",
    !userInfo.Roles.find((x) => x === "Dentist") && "Doctor Name",
    "Last Updated",
    "Messages",
    "",
    "",
  ];

  const handleLoadMorePrebooksClick = async () => {
    setLoading(true);
    const response = await CaseService.getPrebooks({
      personnelId: userInfo.PracticeWithPersonnel.find(
        (p) => p.practiceId === selectedPractice
      ).personnelId,
      practiceId: selectedPractice,
      skip: prebooks.length,
      take: 20,
    });

    const data = await response.json();
    setPrebooks((prev) => [...prev, ...data.schedules]);
    setLoading(false);
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    GetCaseList(cases.length);
  };

  const handlePrebook = (prebook) => {
    navigate("/prebook/" + prebook.id);
  };

  return (
    <React.Fragment>
      {isFetchingPatient && (
        <LoadingModal open={true} message="Getting case information" />
      )}
      {isALFabModalOpen && (
        <AlignerFabModal open={isALFabModalOpen} dataCase={alFabModalCaseData} dataFiles={alFabModalFilesData} infoUser={userInfo} dataPractices={ practices } />
      )}
      <Header activeTab="/cases" />
      <PageLayout
        header="Cases List"
        banner={{
          image: BannerImage,
          link: "https://evidentdigital.blob.core.windows.net/public/Brochure_DX.pdf",
        }}
      >
        <Box marginY={4}>
          {/* Header */}
          <Stack direction="row" justifyContent="space-between">
            <PracticeTabs
              practices={practices}
              selected={selectedPractice}
              onPracticeChange={handlePracticeChange}
            />
          </Stack>
          <Box
            sx={{
              paddingY: 2,
              paddingX: 4,
              borderRadius: "1rem",
              backgroundColor: "#fff",
              borderRadius: "0 1rem 1rem 1rem",
              textAlign: "center",
            }}
          >
            <Searchbar
              onSearch={handleSearch}
              placeHolder={"Search Case Number, Patient Name"}
            />
            <Divider
              sx={{
                border: 0,
                borderBottom: "1px dashed #6C6C6C",
              }}
            />
            <SearchFilter
              practices={practices}
              filterList={filterList}
              onSelect={handleStatusFilter}
              searchFilter={statusFilter}
            />

            {loading && (
              <Box sx={{ paddingTop: 1 }}>
                <TableSkeleton count={skeletonCount} height="96px" />
              </Box>
            )}
            {!loading &&
              statusFilter === "Pre-booked Cases" &&
              (prebooks.length > 0 ? (
                <>
                  <DataTable
                    headers={[
                      "#",
                      "Patient Name",
                      "Treatment Name",
                      ...(!isDentist ? ["Dentist"] : []),
                      "Treament Plan Call",
                      "General Inquiry Call",
                      "Upload scans by",
                      "",
                    ]}
                  >
                    {prebooks.map((prebook, i) => (
                      <TableRow
                        key={JSON.stringify(prebook)}
                        row={[
                          i + 1,
                          `${prebook.patientFirstName} ${prebook.patientLastName}`,
                          prebook.treatmentName,
                          ...(!isDentist
                            ? [
                                `${prebook.dentistFirstName} ${prebook.dentistLastName}`,
                              ]
                            : []),

                            prebook.scheduleCallWithManager ? "Yes" : "No",
                            prebook.scheduleCallWithSupport ? "Yes" : "No",
                          moment
                            .utc(prebook.date)
                            .local()
                            .format("MMMM DD YYYY - h:mm a"),
                          <Box sx={{ width: "100%" }}>
                            <ActionButton
                              color="brand"
                              onClick={() => handlePrebook(prebook)}
                            >
                              Start Order
                            </ActionButton>
                          </Box>,
                        ]}
                      />
                    ))}
                  </DataTable>
                  {prebooks && totalPrebooks > prebooks.length && (
                    <Box
                      sx={{
                        width: "20%",
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "55px",
                        background: "#eaecf1",
                        color: "#000",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "400",
                      }}
                      onClick={handleLoadMorePrebooksClick}
                    >
                      Load More
                    </Box>
                  )}
                </>
              ) : (
                <EmptyAlert resource="pre-booked cases" />
              ))} 
            {!loading &&
              statusFilter !== "Pre-booked Cases" &&
              (rows.length > 0 ? (
                <React.Fragment>
                  <DataTable headers={headers}>
                    {rows.map((row, i) => (
                      <ExpandingTableRow
                        key={JSON.stringify(row)}
                        row={row}
                        expandContent={expandContents[i]}
                      />

                      // <TableRow
                      //   key={i}
                      //   row={row}
                      //   onClick={handleRowClicks[i]}
                      //   disableLastCell
                      // />
                    ))}
                  </DataTable>

                  {totalCasesCount > cases.length && (
                    <Box
                      sx={{
                        width: "20%",
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "55px",
                        background: "#eaecf1",
                        color: "#000",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "400",
                      }}
                      onClick={handleLoadMore}
                    >
                      Load More
                    </Box>
                  )}
                </React.Fragment>
              ) : (
                <EmptyAlert resource="cases" />
              ))}
          </Box>
        </Box>
      </PageLayout>
    </React.Fragment>
  );
};

export default Cases;
